@font-face {
    font-family: 'Helvetica Now Text Regular';
    src: local('Helvetica Now Text Regular'), local('Helvetica-Now-Text--Regular'),
    url('./fonts/regular/HelveticaNowText-Regular.woff2') format('woff2'),
    url('./fonts/regular/HelveticaNowText-Regular.woff') format('woff'),
    url('./fonts/regular/HelveticaNowText-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

body {
    color: #444;
    background-color: #f8f8f8;
    margin: 0;
    font-family: Helvetica Now Text Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#spacer {
    flex-grow: 1;
}

.personal-container {
    color: #fff;
    text-align: center;
    padding: 4rem 1rem;
    background-color: var(--mdb-primary);
}

.content-container {
    margin: 2rem auto;
}

.main-title {
    margin: 0 auto;
    line-height: 1.5em;
}

.main-title span {
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 100% 3px;
}

.primary span {
    background-image: linear-gradient(to right, var(--mdb-primary) 0%, var(--mdb-primary) 100%);
}

.cyan span {
    background-image: linear-gradient(to right, var(--mdb-cyan) 0%, var(--mdb-cyan) 100%);
}
